import * as React from 'react';

import { cn } from '@/src/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    VariantProps<typeof inputVariants> {}

export const inputVariants = cva(
  'flex w-full px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'rounded-md border border-input bg-background ring-offset-background focus-visible:ring-1 ring-primary/50 focus-visible:ring-offset-1',
        unstyled: '',
      },
      size: {
        default: 'h-10 px-4 py-2',
        lg: 'h-12 text-xl',
        none: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, size, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ size, variant }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
